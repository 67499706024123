var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c(
        "div",
        { staticClass: "pa-4" },
        [
          _c("BaseLoadingAllScreen", { attrs: { customDisplay: "" } }, [
            _c(
              "div",
              { attrs: { slot: "object-inside" }, slot: "object-inside" },
              [
                _c(
                  "div",
                  { staticClass: "wrapper-object" },
                  [
                    _c(
                      "v-progress-circular",
                      {
                        attrs: {
                          rotate: 180,
                          size: 100,
                          width: 10,
                          value: _vm.value,
                          color: "white",
                        },
                      },
                      [_vm._v(_vm._s(_vm.value) + "%")]
                    ),
                    !_vm.isCustomStatus
                      ? _c("div", { staticClass: "status text-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.COURSE.DEFAULT_PROGRESS_BAR",
                                  _vm.displayStatus,
                                  _vm.entity,
                                  _vm.numberOfQuestion
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c("div", { staticClass: "status status-custom" }, [
                          _vm._v(" " + _vm._s(_vm.customStatus) + " "),
                        ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }