var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-date-picker" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "290px",
            "min-width": "290px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        staticClass: "text-body-2",
                        attrs: {
                          "append-icon": "mdi-calendar",
                          dense: "",
                          outlined: "",
                          label: _vm.label,
                          placeholder: _vm.placeholder,
                          readonly: "",
                          rules: _vm.rules,
                        },
                        model: {
                          value: _vm.dateFormatted,
                          callback: function ($$v) {
                            _vm.dateFormatted = $$v
                          },
                          expression: "dateFormatted",
                        },
                      },
                      on
                    ),
                    [
                      _vm.date
                        ? _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "cursor-pointer",
                                  on: { click: _vm.clearDate },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("v-date-picker", {
            attrs: { "no-title": "", range: _vm.range, min: _vm.min },
            on: {
              input: function ($event) {
                _vm.menu = false
              },
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }