var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "900" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c("div", [
                _vm._v(
                  _vm._s(
                    _vm.$vuetify.lang.t(
                      "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.TITLE"
                    )
                  )
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("onClose")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pa-0 modal-library__main-content" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-10",
                      staticStyle: { "border-right": "1px solid #dddbda" },
                      attrs: { cols: "7" },
                    },
                    [
                      _c("BaseInputSearch", {
                        attrs: {
                          label: _vm.$vuetify.lang.t(
                            "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.LABEL_FILE_NAME"
                          ),
                          placeholder: _vm.$vuetify.lang.t(
                            "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.PLACEHOLDER_FILTER_FILE_NAME"
                          ),
                        },
                        on: { onSearch: (text) => _vm.onSearch(text) },
                      }),
                      _vm.loading
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", "model-value": "20" },
                          })
                        : _vm._e(),
                      !_vm.loading
                        ? _c("ListImageThumbnail", {
                            attrs: {
                              listImages: _vm.files,
                              total: Math.ceil(_vm.countDocument / 10),
                              pageNumber: _vm.pageNumber + 1,
                            },
                            on: {
                              nextPage: _vm.nextPage,
                              previousPage: _vm.previousPage,
                              onSelect: (item) => _vm.onSelect(item),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-5", attrs: { cols: "5" } },
                    [
                      _c("AttachmentDetail", {
                        attrs: { selectedImage: _vm.selectedItem },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "py-4" },
            [
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  isButtonText: "",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.COURSE.BUTTON_CANCEL_TEXT"
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("onClose")
                  },
                },
              }),
              _c("BaseButton", {
                attrs: {
                  disabled: !_vm.selectedItem,
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.BUTTON_SELECT"
                  ),
                  dark: false,
                },
                on: { click: _vm.submit },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }