var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    { attrs: { value: _vm.isOpen, persistent: "", "max-width": "800px" } },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            { staticClass: "pa-4" },
            [
              _vm.isOpen
                ? _c("BaseEditor", {
                    ref: "editor",
                    attrs: { "init-data": _vm.tempHtml },
                    on: { "text-change": _vm.onTextChange },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "pa-2" },
            [
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  isButtonText: "",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.COURSE_CONTENT.BUTTON_CANCEL_TEXT"
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              }),
              _c("BaseButton", {
                attrs: {
                  className: "mr-2",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.COURSE_CONTENT.BUTTON_OK"
                  ),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("accept", _vm.tempHtml)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }