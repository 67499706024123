var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { color: "grey darken-3", top: "", "close-delay": "50" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-icon",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "cursor-pointer",
                      attrs: { color: _vm.iconColor, small: _vm.isIconSmall },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("click")
                        },
                      },
                    },
                    "v-icon",
                    attrs,
                    false
                  ),
                  on
                ),
                [_vm._v(" " + _vm._s(_vm.iconName) + " ")]
              ),
            ]
          },
        },
      ]),
    },
    [_c("span", [_vm._v(_vm._s(_vm.tooltipText))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }