var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "350" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "form", staticClass: "d-inline-flex align-center" },
                [
                  _c("BaseInput", {
                    staticClass: "mt-7 mr-4",
                    staticStyle: { width: "210px" },
                    attrs: {
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.COURSE_UNIT_RESOURCE_SELECTION.LABEL_VIDEO_LINK"
                      ),
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.COURSE_UNIT_RESOURCE_SELECTION.PLACEHOLDER_INPUT_LINK"
                      ),
                      rules: [_vm.$rules.required, _vm.$rules.url],
                    },
                    model: {
                      value: _vm.link,
                      callback: function ($$v) {
                        _vm.link = $$v
                      },
                      expression: "link",
                    },
                  }),
                  _c("BaseButton", {
                    attrs: {
                      text: _vm.$vuetify.lang.t("$vuetify.COURSE.BUTTON_INPUT"),
                    },
                    on: { click: _vm.onselect },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }