var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.submission
    ? _c(
        "div",
        { staticClass: "canvas-element-exam" },
        [
          _c("div", { staticClass: "exam-duration" }, [
            _vm._v("Email: "),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.submission.email)),
            ]),
          ]),
          _c("div", { staticClass: "exam-duration" }, [
            _vm._v("Học sinh: "),
            _c("span", { staticClass: "font-weight-medium" }, [
              _vm._v(_vm._s(_vm.submission.name)),
            ]),
          ]),
          _vm.isMarkShown
            ? _c("div", { staticClass: "exam-duration" }, [
                _vm._v(
                  "Điểm: " +
                    _vm._s(`${_vm.submission.correct}/${_vm.submission.total}`)
                ),
              ])
            : _vm._e(),
          _c("v-divider", { staticClass: "my-2" }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }