var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "BaseUploadDragger",
        {
          ref: "uploader",
          attrs: {
            type: _vm.type,
            maxFilesize: _vm.maxFilesize,
            maxFiles: _vm.maxFiles,
            uploadMultiple: _vm.uploadMultiple,
          },
          on: { onUploadSuccess: (data) => _vm.onUploadSuccess(data) },
        },
        [
          _vm.hasLibrary
            ? _c(
                "div",
                { staticClass: "relative", staticStyle: { "z-index": "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "button-upload--library",
                      attrs: { small: "", depressed: "", dark: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.openLibraryDialog.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v(" mdi-image-multiple "),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$vuetify.lang.t(
                              "$vuetify.COURSE.BASE_UPLOAD_IMAGE.BUTTON_SELECT_FROM_LIBRARY"
                            )
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                  _c("img", {
                    staticClass: "image-upload",
                    attrs: {
                      src: _vm.imageUrl
                        ? _vm.$utils.convertURL(_vm.imageUrl)
                        : _vm.helpers.DEFAULT_IMAGE.banner,
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "button-upload--image mb-1",
                      attrs: { depressed: "", dark: "" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$vuetify.lang.t(
                            "$vuetify.COURSE.BASE_UPLOAD_IMAGE.BUTTON_UPLOAD_IMAGE"
                          )
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "icon-button-upload has-dashed background-gray",
                },
                [
                  _c(
                    "v-btn",
                    { attrs: { block: "", plain: "", color: "#2e4493" } },
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  ),
                ],
                1
              ),
        ]
      ),
      _c("ModalLibrary", {
        ref: "library",
        on: {
          onSelect: (selected) => _vm.onSelected([selected]),
          onClose: _vm.onClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }