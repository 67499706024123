var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-radio-group",
    _vm._b(
      {
        model: {
          value: _vm.data,
          callback: function ($$v) {
            _vm.data = $$v
          },
          expression: "data",
        },
      },
      "v-radio-group",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.items, function (item) {
      return _c("v-radio", {
        key: item.value,
        attrs: { color: _vm.color, label: `${item.title}`, value: item.value },
        on: { change: _vm.onChange },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }