var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-dropzone",
    {
      ref: "dropzone",
      attrs: {
        id: "dropzone",
        "include-styling": false,
        useCustomSlot: true,
        options: _vm.dropzoneOptions,
      },
    },
    [
      _vm._t("default"),
      _vm.loading
        ? _c("v-progress-linear", {
            staticClass: "mt-4",
            attrs: { value: _vm.percent },
          })
        : _vm._e(),
      _c("BaseUploadDraggerList", {
        ref: "multi-confirm-popup",
        attrs: {
          type: _vm.type,
          singleUploadFileSize: _vm.singleUploadFileSize,
        },
        on: { onUploadSuccess: (val) => _vm.$emit("onUploadSuccess", val) },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }