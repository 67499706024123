var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("BaseInput", {
        staticClass: "mb-5",
        staticStyle: { "max-width": "360px", height: "40px" },
        attrs: { label: _vm.label, placeholder: _vm.placeholder },
        nativeOn: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.$emit("onSearch", _vm.textSearch)
          },
        },
        model: {
          value: _vm.textSearch,
          callback: function ($$v) {
            _vm.textSearch = $$v
          },
          expression: "textSearch",
        },
      }),
      _c("BaseButton", {
        staticClass: "ml-3",
        staticStyle: { "margin-top": "0px", height: "40px" },
        attrs: {
          outlined: _vm.outlined,
          icon: _vm.magnifyIcon,
          text: _vm.buttonText || _vm.$vuetify.lang.t("$vuetify.BUTTON_SEARCH"),
        },
        on: {
          click: function ($event) {
            return _vm.$emit("onSearch", _vm.textSearch)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }