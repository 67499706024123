var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("v-treeview", {
                attrs: {
                  items: _vm.items,
                  "selection-type": _vm.selectionType,
                  selectable: "",
                  "return-object": "",
                  "item-key": "id",
                  "item-text": "title",
                  "open-all": "",
                },
                model: {
                  value: _vm.selection,
                  callback: function ($$v) {
                    _vm.selection = $$v
                  },
                  expression: "selection",
                },
              }),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "v-col",
            { staticClass: "pa-6", attrs: { cols: "6" } },
            [
              !_vm.selection.length
                ? [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.MANAGE_FINANCE.POPUP_ADD_PROGRAM.TITLE_TREE_SELECT_TOPIC"
                        )
                      ) + " "
                    ),
                  ]
                : _vm._l(_vm.selection, function (node) {
                    return _c("div", { key: node.id }, [
                      _vm._v(" " + _vm._s(node.title) + " "),
                    ])
                  }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }