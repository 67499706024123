var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "element video-player" }, [
    _vm.canPlay
      ? _c("video", {
          ref: "videoPlayer",
          staticClass: "video-js vjs-default-skin vjs-big-play-centered",
          attrs: { preload: "auto" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }