var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-navigation-drawer",
        { attrs: { permanent: "", app: "", width: "100%" } },
        [
          _c(
            "v-app-bar",
            {
              attrs: {
                absolute: "",
                "clipped-left": "",
                flat: "",
                height: "72",
              },
            },
            [_c("ActionsBar", { ref: "appBar", on: { onSave: _vm.onSave } })],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row full-height" },
            [
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    permanent: "",
                    color: "grey darken-3",
                    width: "100",
                  },
                },
                [
                  _c("ListNavigations", {
                    attrs: {
                      onSave: _vm.onSave,
                      selected: _vm.selected,
                      checkChangedData: _vm.checkChangedData,
                    },
                    on: {
                      onSelectNavigation: (key) => _vm.onSelectNavigation(key),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                { attrs: { permanent: "", width: "100%" } },
                [
                  _c("EditDetailPart", {
                    ref: "edit-part",
                    attrs: {
                      selected: _vm.selected,
                      reloadIframe: _vm.reloadIframe,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }