var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-spacing" },
    [
      _vm._l(_vm.listNavigations, function (navigation) {
        return _c(
          "div",
          {
            key: navigation.title,
            class:
              _vm.selectedNavigation === navigation.id
                ? "color--white box-icon box-icon__active cursor-pointer"
                : "color--white box-icon cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.onSelectEditPart(navigation)
              },
            },
          },
          [
            _c("v-icon", { attrs: { color: "white" } }, [
              _vm._v(_vm._s(navigation.icon)),
            ]),
            _c("div", { staticClass: "mt-3" }, [
              _vm._v(_vm._s(navigation.title)),
            ]),
          ],
          1
        )
      }),
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-center flex-column align-center cursor-pointer addition-button",
          on: { click: () => _vm.onAddPage() },
        },
        [
          _c("v-icon", { attrs: { color: "white" } }, [_vm._v("mdi-plus")]),
          _c("div", { staticClass: "mt-1 white--text" }, [
            _vm._v(_vm._s("Thêm trang")),
          ]),
        ],
        1
      ),
      _c("PopupAddNewPage", {
        ref: "addition-page",
        on: { onAddNewPage: (data) => _vm.onAddNewPage(data) },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }