var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "preview__audio_content" }, [
    _vm.link
      ? _c(
          "div",
          {
            staticClass: "main_audio container_plyr",
            attrs: { id: "main-audio" },
          },
          [
            _c("vue-plyr", [
              _vm.link
                ? _c(
                    "audio",
                    {
                      ref: "player",
                      staticClass: "audio_preview",
                      attrs: { controls: "", id: `audio_player_${_vm.link}` },
                    },
                    [
                      _c("source", {
                        attrs: { src: _vm.link, type: "audio/mpeg" },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      : _c("div", [_vm._v("Audio hiện không khả dụng")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }