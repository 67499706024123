var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.question.answers, function (answer, answerIndex) {
      return _c(
        "div",
        { key: answerIndex, staticClass: "d-flex align-item-center" },
        [
          _c("div", { staticStyle: { display: "inline-flex" } }, [
            _vm._v(_vm._s(answer.label) + "."),
          ]),
          _c("div", {
            staticStyle: { display: "inline-flex", "margin-left": "4px" },
            domProps: { innerHTML: _vm._s(answer.value || "") },
          }),
          _vm.isAnswerShown
            ? _c(
                "div",
                {
                  staticClass: "ml-2",
                  staticStyle: { display: "inline-flex" },
                },
                [
                  _vm.isStudentAnswerShown(answer.key)
                    ? _c(
                        "div",
                        [
                          !_vm.isCorrect
                            ? _c(
                                "v-icon",
                                { attrs: { size: "small", color: "red" } },
                                [_vm._v("mdi-close")]
                              )
                            : _c(
                                "v-icon",
                                { attrs: { size: "small", color: "green" } },
                                [_vm._v("mdi-check")]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }