var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        staticClass: "custom-dropdown",
        attrs: {
          items: _vm.items,
          "item-text": _vm.itemText,
          "item-value": _vm.itemValue,
          outlined: "",
          dense: "",
          multiple: "",
          "return-object": "",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function ({ item, attrs }) {
                return [
                  _vm.getValue(item) == _vm.actionCustomValue
                    ? [
                        _vm._t("action-custom"),
                        !_vm.$slots["action-custom"]
                          ? [_vm._v(" " + _vm._s(_vm.getText(item)) + " ")]
                          : _vm._e(),
                      ]
                    : [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center full-width" },
                          [
                            attrs["aria-selected"] === "true"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mr-2 checkbox checkbox--checked",
                                  },
                                  [
                                    _c("v-icon", { attrs: { size: "16px" } }, [
                                      _vm._v("mdi-check"),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("span", {
                                  staticClass:
                                    "mr-2 checkbox checkbox--not-checked",
                                }),
                            _c(
                              "span",
                              {
                                staticClass: "ellipsis-one-line d-inline-block",
                              },
                              [_vm._v(_vm._s(_vm.getText(item)))]
                            ),
                          ]
                        ),
                      ],
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.tempValue,
          callback: function ($$v) {
            _vm.tempValue = $$v
          },
          expression: "tempValue",
        },
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }