var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isAdmin
        ? _c(
            "v-dialog",
            {
              attrs: { width: "800px", scrollable: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "headline lighten-2 email-dialog__header",
                      attrs: { "primary-titledialog": "" },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$vuetify.lang.t(
                            "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND_EMAIL"
                          )
                        ) + " "
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.resetPopup } },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "py-5" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.form,
                            callback: function ($$v) {
                              _vm.form = $$v
                            },
                            expression: "form",
                          },
                        },
                        [
                          _vm.sentStudent
                            ? _c("v-checkbox", {
                                staticClass: "mt-0",
                                attrs: {
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_PICK_ALL_STUDENT"
                                  ),
                                },
                                model: {
                                  value: _vm.isSelectAllStudent,
                                  callback: function ($$v) {
                                    _vm.isSelectAllStudent = $$v
                                  },
                                  expression: "isSelectAllStudent",
                                },
                              })
                            : _vm._e(),
                          !_vm.isSelectAllStudent && _vm.sentStudent
                            ? _c("BaseAutocomplete", {
                                attrs: {
                                  rules: [_vm.$rules.minLength(1)],
                                  label: _vm.$vuetify.lang.t(
                                    "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_PICK_STUDENT"
                                  ),
                                  placeholder: _vm.$vuetify.lang.t(
                                    "$vuetify.USER_MANAGE.STUDENT_LIST.TITLE"
                                  ),
                                  items: _vm.students,
                                  "item-text": "user.name",
                                  "item-value": "user.id",
                                  returnObject: false,
                                  multiple: "",
                                  "small-chips": "",
                                  "deletable-chips": "",
                                },
                                model: {
                                  value: _vm.studentsChoice,
                                  callback: function ($$v) {
                                    _vm.studentsChoice = $$v
                                  },
                                  expression: "studentsChoice",
                                },
                              })
                            : _vm._e(),
                          _c("BaseInput", {
                            staticClass: "mt-2",
                            attrs: {
                              label: _vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_EMAIL_SUBJECTS"
                              ),
                              rules: [_vm.$rules.required],
                              placeholder: _vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_EMAIL_SUBJECTS"
                              ),
                            },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                          _c("BaseEditor", {
                            ref: "edit-html",
                            attrs: { rules: [_vm.$rules.required] },
                            on: {
                              "text-change": (html) => (_vm.editorHtml = html),
                            },
                          }),
                          _c("input", {
                            ref: "input-html-file",
                            staticStyle: { display: "none" },
                            attrs: {
                              id: "imp-html",
                              type: "file",
                              accept: ".html",
                            },
                            on: { change: _vm.handleFileSelect },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "py-4 px-6" },
                    [
                      _c("BaseButton", {
                        attrs: {
                          outlined: "",
                          text: _vm.$vuetify.lang.t(
                            "$vuetify.SYSTEM_SETTING.EMAIL_CONTENT_SETTING_FORM.BUTTON_IMPORT_TEMPLATE"
                          ),
                        },
                        on: { click: _vm.importHtml },
                      }),
                      _c("v-spacer"),
                      _c("BaseButton", {
                        attrs: {
                          text: !_vm.sentStudent
                            ? `${_vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.SEND_EMAIL_TO"
                              )}`
                            : _vm.isSelectAllStudent
                            ? _vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_SEND_EMAIL_ALL"
                              )
                            : `${_vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TH_SEND_EMAIL_TO"
                              )} ${
                                _vm.studentsChoice.length
                              } ${_vm.$vuetify.lang.t(
                                "$vuetify.USER_MANAGE.STUDENT_LIST.TITLE"
                              )}`,
                        },
                        on: { click: _vm.onSubmit },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("SentNotificationPopup", {
                ref: "send-email",
                attrs: { type: "email" },
              }),
              _c("ErrorNotificationPopup", {
                ref: "error-email",
                attrs: { type: "email" },
              }),
              _c("BaseProgressBar", {
                ref: "progress-bar",
                attrs: { entity: "email" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }