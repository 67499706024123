var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "800", scrollable: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "heading grey--text text--darken-4",
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog()
                        },
                      },
                    },
                    [_vm._v(" mdi-close ")]
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                {
                  staticClass: "d-flex align-center justify-center",
                  on: {
                    click: function ($event) {
                      _vm.canWatchData = true
                    },
                  },
                },
                [
                  _c("BaseEditor", {
                    key: _vm.keyEditor,
                    ref: "edit-html",
                    staticClass: "mt-5 mb-1 popup-dialog__content",
                    attrs: {
                      height: "200",
                      "init-data": _vm.data.html,
                      minHeight: "0",
                      customHeight: "88",
                    },
                    on: { "text-change": _vm.onTextChange },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-6 py-4 d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "primary",
                        disabled: !_vm.isDataChange,
                      },
                      on: { click: _vm.onSaveTemplate },
                    },
                    [
                      _c("span", { staticClass: "text-none" }, [
                        _vm._v(" " + _vm._s("Lưu") + " "),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("input", {
        ref: "input-html-file",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: ".html" },
        on: { change: _vm.handleFileSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }