var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "full-height full-width d-flex justify-center",
      on: {
        contextmenu: function ($event) {
          $event.preventDefault()
          return ((e) => e.preventDefault()).apply(null, arguments)
        },
      },
    },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "mt-12",
            attrs: { indeterminate: "", color: "primary" },
          })
        : _c("iframe", {
            staticClass: "full-height no-border",
            style: `width: ${_vm.widthIframe}`,
            attrs: {
              id: "learning-iframe",
              src: _vm.currentDomain,
              title: _vm.$vuetify.lang.t(
                "$vuetify.SITE_BUILDER.IFRAME_LEARNING_PAGE_TITLE"
              ),
            },
            on: { mouseup: _vm.clickInsideIframe },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }