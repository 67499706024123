var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "outlined" },
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            staticClass: "body-2",
            attrs: {
              items: _vm.items,
              outlined: "",
              label: _vm.label,
              "item-text": _vm.itemText,
              placeholder: _vm.placeholder,
              "item-value": _vm.itemValue,
              "return-object": _vm.returnObject,
              "search-input": _vm.search,
              rules: _vm.rules,
              dense: "",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.search = $event
              },
              "update:search-input": function ($event) {
                _vm.search = $event
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "label",
                  fn: function () {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.label)),
                        _vm.isRequiredTitle
                          ? _c("strong", { staticClass: "red--text ml-1" }, [
                              _vm._v("*"),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "prepend-item",
                  fn: function () {
                    return [_vm._t("pre-item")]
                  },
                  proxy: true,
                },
                {
                  key: "item",
                  fn: function ({ item, attrs }) {
                    return [
                      _vm.getValue(item) == _vm.actionCustomValue
                        ? [
                            _vm._t("action-custom"),
                            !_vm.$slots["action-custom"]
                              ? [_vm._v(" " + _vm._s(_vm.getText(item)) + " ")]
                              : _vm._e(),
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center full-width" },
                              [
                                attrs["aria-selected"] === "true"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mr-2 checkbox checkbox--checked",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16px" } },
                                          [_vm._v("mdi-check")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("span", {
                                      staticClass:
                                        "mr-2 checkbox checkbox--not-checked",
                                    }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "ellipsis-one-line d-inline-block",
                                  },
                                  [_vm._v(_vm._s(_vm.getText(item)))]
                                ),
                              ]
                            ),
                          ],
                    ]
                  },
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.data,
              callback: function ($$v) {
                _vm.data = $$v
              },
              expression: "data",
            },
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }