var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "BaseUploadDragger",
        {
          staticClass: "cursor-pointer full-width",
          attrs: { type: _vm.type, maxFilesize: _vm.maxFilesize },
          on: { onUploadSuccess: (data) => _vm.onUploadSuccess(data) },
        },
        [
          _c("div", { staticClass: "wrapper" }, [
            _c("span", { staticClass: "select-file text-body-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$vuetify.lang.t("$vuetify.COURSE.LABEL_SELECT_FILE")
                  ) +
                  " "
              ),
            ]),
            _c(
              "span",
              { ref: "file-name", staticClass: "file-name" },
              [
                _vm.name
                  ? _c("BaseFileName", { attrs: { name: _vm.name } })
                  : _c(
                      "span",
                      {
                        staticClass: "text-body-2",
                        staticStyle: { opacity: "0.6" },
                      },
                      [_vm._v(_vm._s(_vm.placeholder))]
                    ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.isDownloadAllowed && _vm.isBtnDownloadShown
        ? _c(
            "v-tooltip",
            {
              attrs: { color: "grey darken-3", top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "ml-2 action-hover rounded",
                                attrs: { icon: "", outlined: "" },
                                on: { click: _vm.onDownloadFile },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-download")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                955211005
              ),
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$vuetify.lang.t("$vuetify.COURSE.BUTTON_DOWNLOAD"))
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }