var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    attrs: {
      "item-text": "title",
      "auto-select-first": "",
      dense: "",
      outlined: "",
      flat: "",
      "hide-details": _vm.hideDetails,
      label: _vm.label,
      items: _vm.resourcesList.filter((v) => v),
      "search-input": _vm.search,
      "return-object": "",
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event
      },
      "update:search-input": function ($event) {
        _vm.search = $event
      },
    },
    model: {
      value: _vm.data,
      callback: function ($$v) {
        _vm.data = $$v
      },
      expression: "data",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }