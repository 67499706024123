var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      class: `text-none ${_vm.className}`,
      attrs: {
        depressed: "",
        outlined: _vm.outlined,
        dark: _vm.dark,
        text: _vm.isButtonText,
        color: _vm.color,
        loading: _vm.loading,
        disabled: _vm.disabled,
        height: _vm.height,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm.icon && !_vm.surffix
        ? _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " "),
          ])
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm.icon && _vm.surffix
        ? _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
            _vm._v(" " + _vm._s(_vm.icon) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }