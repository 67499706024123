var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisibility,
          expression: "isVisibility",
        },
      ],
    },
    [
      _c(
        "div",
        { key: _vm.rendererTime, attrs: { id: "pdf-renderer-exam-review" } },
        [
          _c("div", { staticClass: "text-center text-uppercase text-h5" }, [
            _vm._v("Báo cáo nhận xét kết quả"),
          ]),
          _c(
            "div",
            { staticClass: "text-center text-uppercase text-h6 mt-2 mb-4" },
            [_vm._v(_vm._s(_vm.unitTitle))]
          ),
          _c("StudentInfo", { attrs: { isMarkShown: false } }),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-space-between align-center ml-4 mb-4 canvas-element",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column justify-center aligh-center mx-2",
                },
                [
                  _vm._m(0),
                  _c("div", { staticClass: "red--text text-h6 text-center" }, [
                    _vm._v(_vm._s(_vm.mark)),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "mx-2" },
                [
                  _c("ReviewGraph", {
                    staticStyle: {
                      position: "relative",
                      height: "250px",
                      width: "450px",
                    },
                    attrs: {
                      partitionReviewStats: _vm.reviewStats,
                      id: "review-graph",
                    },
                    on: { onRendered: _vm.onReviewGraphRendered },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-space-between ml-4 full-width mb-4 canvas-element",
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-medium mb-2",
                    staticStyle: { color: "#797979" },
                  },
                  [_vm._v("Câu trả lời")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex progress-line",
                    staticStyle: {
                      "background-color": "#e5e7eb",
                      width: "300px",
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "green progress-line",
                      style: `width: ${_vm.correctPercentage}%`,
                    }),
                    _c("div", {
                      staticClass: "red progress-line",
                      style: `width: ${_vm.wrongPercentage}%`,
                    }),
                  ]
                ),
                _c("div", { staticClass: "d-flex mt-2" }, [
                  _vm.correctQuantity
                    ? _c(
                        "div",
                        { staticClass: "mr-2 d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "green", "x-small": "" },
                            },
                            [_vm._v("mdi-circle")]
                          ),
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v(_vm._s(_vm.correctQuantity) + " câu đúng"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.wrongQuantity
                    ? _c(
                        "div",
                        { staticClass: "mr-2 d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "red", "x-small": "" },
                            },
                            [_vm._v("mdi-circle")]
                          ),
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v(_vm._s(_vm.wrongQuantity) + " câu sai"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.remainQuantity
                    ? _c(
                        "div",
                        { staticClass: "mr-2 d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "grey", "x-small": "" },
                            },
                            [_vm._v("mdi-circle")]
                          ),
                          _c("div", { staticClass: "text-caption" }, [
                            _vm._v(
                              _vm._s(_vm.remainQuantity) + " câu chưa trả lời"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-medium mb-2",
                    staticStyle: { color: "#797979" },
                  },
                  [_vm._v("Thời gian làm bài")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex progress-line",
                    staticStyle: {
                      "background-color": "#e5e7eb",
                      width: "300px",
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "progress-line",
                      style: `background-color: #0084ff; width: ${_vm.timePercentage}%`,
                    }),
                  ]
                ),
                _c("div", { staticClass: "mt-2 text-caption" }, [
                  _vm._v("Hoàn thành trong " + _vm._s(_vm.timeProgressText)),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column ml-4 full-width mb-4 canvas-element",
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-weight-medium mb-2",
                  staticStyle: { color: "#797979" },
                },
                [_vm._v("Chi tiết điểm")]
              ),
              _c(
                "ul",
                { staticClass: "full-width" },
                _vm._l(_vm.reviewStats, function (stat, index) {
                  return _c("li", { key: index, staticClass: "full-width" }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-space-between text-body-2",
                        staticStyle: { width: "80%" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "black--text font-weight-medium" },
                          [_vm._v("Phần " + _vm._s(stat.title))]
                        ),
                        _c("div", [
                          _c(
                            "span",
                            { staticClass: "green--text font-weight-medium" },
                            [
                              _vm._v(
                                _vm._s(_vm.padNumber(stat.correctQuantity))
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "black--text font-weight-medium" },
                            [
                              _vm._v(
                                "/" + _vm._s(_vm.padNumber(stat.totalQuantity))
                              ),
                            ]
                          ),
                          _c("span", { attrs: { className: "grey--text" } }, [
                            _vm._v(" câu đúng"),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "font-weight-medium mb-2 ml-4 canvas-element",
              staticStyle: { color: "#797979" },
            },
            [_vm._v("Nhận xét của giáo viên")]
          ),
          _vm._l(_vm.reviewDetails, function (detail, index) {
            return _c(
              "div",
              { key: index, staticClass: "mb-2 ml-4 canvas-element" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "mr-1",
                        attrs: { color: "black", "x-small": "" },
                      },
                      [_vm._v("mdi-circle")]
                    ),
                    _c(
                      "span",
                      { staticClass: "font-weight-medium text-body-2" },
                      [
                        _vm._v(
                          _vm._s(!detail.noPrefix ? "Phần " : "") +
                            _vm._s(detail.title)
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "text-body-2 mt-1",
                    staticStyle: { "white-space": "break-spaces" },
                  },
                  [_vm._v(_vm._s(detail.text))]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c("div", { attrs: { id: "pdf-renderer-temp-exam-review" } }),
      _c("div", { attrs: { id: "description-exam-review" } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "font-weight-medium text-center" }, [
      _vm._v("Hoàn thành với"),
      _c("br"),
      _vm._v("số điểm là"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }