var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "color-input d-flex align-center" }, [
    _c("div", { staticClass: "color-input-value" }, [
      _vm._v(" " + _vm._s(_vm.stringColorValue) + " "),
    ]),
    _c("div", { staticClass: "button-selecte-color" }, [
      _c("div", { ref: "color-picker", staticClass: "color-picker" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }