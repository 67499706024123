var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question-overview" }, [
    _vm.description
      ? _c(
          "div",
          { staticClass: "question-overview__parent" },
          _vm._l(_vm.description, function (element, i) {
            return _c(
              "div",
              {
                key: "parent" + i,
                staticClass:
                  "question-overview__description canvas-element-exam",
                class: { "is-last": i === _vm.description.length - 1 },
              },
              [
                _c("div", { staticClass: "question-overview__parent-title" }, [
                  _vm._v("Câu hỏi nhóm ('Điền trực tiếp vào câu hỏi')"),
                ]),
                _c("div", { domProps: { innerHTML: _vm._s(element) } }),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }