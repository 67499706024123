var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BaseTitleTable", {
        attrs: {
          isBack: "",
          backText: _vm.$vuetify.lang.t(
            "$vuetify.SITE_BUILDER.DETAIL.GO_BACK_TEXT"
          ),
          textClass: "subtitle-blur action-hover",
        },
        on: { click: _vm.backToListSection },
      }),
      _c("div", { staticClass: "text-h6 font-weight-medium mb-3" }, [
        _vm._v(" " + _vm._s(_vm.$vuetify.lang.t(_vm.label)) + " "),
      ]),
      _c(_vm.currentComponent, {
        ref: "section",
        tag: "component",
        attrs: {
          initData: _vm.localOriginData,
          openPopup: _vm.openPopup,
          isMobile: _vm.isMobile,
        },
        on: { update: (data) => _vm.handleChangeData(data) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }