var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-spacing" },
    _vm._l(_vm.listNavigations, function (navigation) {
      return _c(
        "div",
        {
          key: navigation.title,
          class:
            _vm.selectedNavigation === navigation.id
              ? "color--white box-icon box-icon__active cursor-pointer"
              : "color--white box-icon cursor-pointer",
          on: {
            click: function ($event) {
              return _vm.onSelectEditPart(navigation)
            },
          },
        },
        [
          _c("v-icon", { attrs: { color: "white" } }, [
            _vm._v(_vm._s(navigation.icon)),
          ]),
          _c("div", { staticClass: "mt-3" }, [
            _vm._v(_vm._s(navigation.title)),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }