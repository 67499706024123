var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseWrapper",
    { attrs: { title: _vm.title, subtitle: _vm.subtitle } },
    [
      _c("BaseButton", {
        attrs: {
          color: "#FF1D30",
          outlined: "",
          text: _vm.buttonText,
          className: "text-none",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("onRemove")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }