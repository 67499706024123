var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-spacing pa-5" },
    [
      _vm.selected === "common"
        ? _c("EditSiteInfo", {
            ref: "common",
            attrs: { viewData: _vm.viewData, seoData: _vm.seoData },
          })
        : _c("EditPageInfo", {
            key: _vm.selected,
            ref: `${_vm.selected}`,
            attrs: { viewData: _vm.viewData },
          }),
      _vm.checkPageAllowSeo(_vm.selected)
        ? _c("EditSeo", { ref: "seo", attrs: { seoData: _vm.seoData } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }