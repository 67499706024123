var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "400px", scrollable: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
          _c("v-card-text", [
            _c("div", { staticClass: "text" }, [
              _vm._v(" " + _vm._s(_vm.textFirst) + " "),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(" " + _vm._s(_vm.textSecond) + " "),
            ]),
          ]),
          _c("v-card-actions", [
            _c(
              "div",
              { staticClass: "button-area" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { outlined: "", color: "primary", depressed: "" },
                    on: { click: () => _vm.closeDialog() },
                  },
                  [
                    _c("span", { staticClass: "text-none" }, [
                      _vm._v(" " + _vm._s("Đóng") + " "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("v-divider"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }