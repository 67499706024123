var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseAutocomplete", {
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      items: _vm.listTeacher,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "hide-details": "",
      clearable: _vm.isClearable,
      returnObject: _vm.returnObject,
      multiple: _vm.multiple,
    },
    model: {
      value: _vm.teacher,
      callback: function ($$v) {
        _vm.teacher = $$v
      },
      expression: "teacher",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }