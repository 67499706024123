var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "py-4 d-flex justify-center flex-column" }, [
    _c(
      "div",
      { staticClass: "text-h5 font-weight-medium text--secondary text-center" },
      [_vm._v(" " + _vm._s(_vm.$vuetify.lang.t(_vm.title)) + " ")]
    ),
    _vm.isSubtitleShown
      ? _c(
          "div",
          { staticClass: "text-subtitle text--secondary text-center" },
          [_vm._v(" " + _vm._s(_vm.$vuetify.lang.t(_vm.subtitle)) + " ")]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "mx-auto pt-4" },
      [
        !_vm.isNewButtonCustomed
          ? _c(
              "v-btn",
              {
                staticClass: "elevation-0 text-none full-width mb-4",
                attrs: { color: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                },
              },
              [
                _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                  _vm._v(_vm._s(_vm.actionButtonIcon)),
                ]),
                _vm._v(_vm._s(_vm.$vuetify.lang.t(_vm.buttonText)) + " "),
              ],
              1
            )
          : _vm._e(),
        _vm._t("custom-new-button"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }