var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_SEND_EMAIL"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-5 pb-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form-send-email",
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c("BaseInput", {
                    staticClass: "mt-2",
                    attrs: {
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_EMAIL_SUBJECTS"
                      ),
                      rules: [_vm.$rules.required],
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.PLACEHOLDER_EMAIL_SUBJECTS"
                      ),
                    },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _c("BaseDropdown", {
                    attrs: {
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.LABEL_EMAIL_TEMPLATE"
                      ),
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_EMAIL_HTML_TEMPLATE"
                      ),
                      rules: [_vm.$rules.required],
                      items: _vm.selectedChoices,
                    },
                    model: {
                      value: _vm.selectedType,
                      callback: function ($$v) {
                        _vm.selectedType = $$v
                      },
                      expression: "selectedType",
                    },
                  }),
                  _vm.selectedType === "editor"
                    ? _c("BaseEditor", {
                        attrs: { rules: [_vm.$rules.required] },
                        on: {
                          "text-change": (html) => (_vm.editorHtml = html),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "py-4" },
            [
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  isButtonText: "",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.BUTTON_CANCEL_TEXT"
                  ),
                },
                on: {
                  click: function ($event) {
                    _vm.dialog = false
                  },
                },
              }),
              _c("BaseButton", {
                attrs: {
                  text: _vm.$vuetify.lang.t("$vuetify.USER_MANAGE.BUTTON_SEND"),
                  dark: false,
                  disabled: !_vm.form,
                },
                on: { click: _vm.onSubmitEmail },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("BaseProgressBar", {
        ref: "progress-bar",
        attrs: { entity: "email" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }