var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisibility,
          expression: "isVisibility",
        },
      ],
    },
    [
      _c(
        "div",
        { key: _vm.rendererTime, attrs: { id: "pdf-renderer" } },
        [
          _c("div", { staticClass: "canvas-element" }, [
            _c("div", { staticClass: "exam-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _c("div", { staticClass: "exam-duration" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.examCode) +
                  _vm._s(
                    _vm.$vuetify.lang.t(
                      "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_TIME"
                    )
                  ) +
                  ": " +
                  _vm._s(_vm.duration) +
                  " " +
                  _vm._s(
                    _vm.$vuetify.lang.t(
                      "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.TEXT_MINUTE"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
          _vm._l(_vm.flattenQuestions, function (question, index) {
            return _c(
              "div",
              { key: index, staticClass: "question-container" },
              [
                _c(
                  "div",
                  { staticClass: "question-overview" },
                  [
                    question.parentHtml
                      ? _c(
                          "div",
                          { staticClass: "question-overview__parent" },
                          _vm._l(question.parentHtml, function (element, i) {
                            return _c(
                              "div",
                              {
                                key: "parent" + i,
                                staticClass:
                                  "question-overview__description canvas-element",
                                class: {
                                  "is-last":
                                    i === question.parentHtml.length - 1,
                                },
                              },
                              [
                                i === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "question-overview__parent-title",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_THREADS"
                                              )
                                            ) +
                                            ": "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", {
                                  domProps: { innerHTML: _vm._s(element) },
                                }),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm._l(question.description, function (element, i) {
                      return _c(
                        "div",
                        {
                          key: "description" + i,
                          staticClass:
                            "question-overview__description canvas-element",
                        },
                        [
                          i === 0
                            ? _c(
                                "div",
                                { staticClass: "question-overview__title" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$vuetify.lang.t(
                                          "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_QUESTION"
                                        )
                                      ) +
                                      " " +
                                      _vm._s(index + 1) +
                                      ": " +
                                      _vm._s(_vm.generateType(question.type)) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c("div", {
                            domProps: { innerHTML: _vm._s(element) },
                          }),
                        ]
                      )
                    }),
                  ],
                  2
                ),
                question.type !== "group-single-choice"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "question-container__answer canvas-element",
                        class: { "is-last": question.isLastQuestion },
                      },
                      [
                        question.type === "fill-blank" ||
                        question.type === "group-fill-blank-child"
                          ? _c(
                              "div",
                              { staticStyle: { "font-weight": "bold" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$vuetify.lang.t(
                                        "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_ANSWER_THE_QUESTION"
                                      )
                                    ) +
                                    ":.................................... "
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              _vm._l(
                                question.answers,
                                function (answer, answerIndex) {
                                  return _c("div", { key: answerIndex }, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { display: "inline-flex" },
                                      },
                                      [_vm._v(_vm._s(answer.label) + ".")]
                                    ),
                                    _c("div", {
                                      staticStyle: {
                                        display: "inline-flex",
                                        "margin-left": "4px",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(answer.value || ""),
                                      },
                                    }),
                                  ])
                                }
                              ),
                              0
                            ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _c("div", { attrs: { id: "pdf-renderer-temp" } }),
      _c("div", { attrs: { id: "description" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }