import { render, staticRenderFns } from "./EditDetailPart.vue?vue&type=template&id=be94ef54&scoped=true&"
import script from "./EditDetailPart.vue?vue&type=script&lang=js&"
export * from "./EditDetailPart.vue?vue&type=script&lang=js&"
import style0 from "./EditDetailPart.vue?vue&type=style&index=0&id=be94ef54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be94ef54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('be94ef54')) {
      api.createRecord('be94ef54', component.options)
    } else {
      api.reload('be94ef54', component.options)
    }
    module.hot.accept("./EditDetailPart.vue?vue&type=template&id=be94ef54&scoped=true&", function () {
      api.rerender('be94ef54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/ScreenBuilder/components/EditDetailPart.vue"
export default component.exports