var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "navbar-spacing pa-5" },
    [
      _vm.selected === "common"
        ? _c("EditSiteInfo", {
            ref: "common",
            attrs: { viewData: _vm.viewData },
          })
        : _c("EditPageInfo", {
            ref: `${_vm.selected}`,
            attrs: {
              viewData: _vm.viewData,
              isSlickDisabled: true,
              isMobile: true,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }