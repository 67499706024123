var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.dialog.persistent, "max-width": "400px" },
      model: {
        value: _vm.dialog.state,
        callback: function ($$v) {
          _vm.$set(_vm.dialog, "state", $$v)
        },
        expression: "dialog.state",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "title break-word" }, [
            _vm._v(" " + _vm._s(_vm.dialog.title) + " "),
          ]),
          _c(
            "v-card-text",
            [
              _vm.dialog.text
                ? _c("span", {
                    staticClass: "body",
                    domProps: { innerHTML: _vm._s(_vm.dialog.text) },
                  })
                : _vm._e(),
              _vm._t("default"),
            ],
            2
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "d-flex pt-0 px-5 pb-5",
              class: _vm.dialog.hideCancel
                ? "justify-end"
                : "justify-space-between",
            },
            [
              _c("BaseButton", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.dialog.hideOk,
                    expression: "!dialog.hideOk",
                  },
                ],
                staticClass: "ma-0 elevation-0",
                attrs: {
                  color: _vm.dialog.confirmColor || "red",
                  outlined: _vm.dialog.doneButtonOutlined,
                  text: _vm.dialog.okText,
                  className: "text-none",
                },
                on: { click: _vm.done },
              }),
              _c("BaseButton", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.dialog.hideCancel,
                    expression: "!dialog.hideCancel",
                  },
                ],
                staticClass: "ma-0 elevation-0",
                attrs: {
                  color: _vm.dialog.cancelColor || "#616161",
                  outlined: _vm.dialog.cancelButtonOutlined,
                  text: _vm.dialog.cancelText,
                  className: "text-none",
                },
                on: { click: _vm.cancel },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }