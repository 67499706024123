var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "base-wrapper bg-white has-border",
      class: { "has-box-shadow": _vm.hasShadow },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "div",
        {
          class: _vm.paddingBottomNone ? "pa-5 pb-0" : _vm.padding,
          style: _vm.customStyleWrapper,
        },
        [
          _vm.title
            ? _c(
                "div",
                { staticClass: "d-flex justify-space-between align-center" },
                [
                  _c("div", { staticClass: "base-wrapper__title" }, [
                    _c(
                      "div",
                      { staticClass: "subtitle" },
                      [
                        _vm.icon
                          ? _c(
                              "v-icon",
                              {
                                staticClass: "mb-1 mr-1",
                                attrs: { small: "" },
                              },
                              [_vm._v(_vm._s(_vm.icon))]
                            )
                          : _vm._e(),
                        _c("span", { staticClass: "subtitle-content" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm.buttonText
                    ? _c("BaseButton", {
                        staticStyle: { height: "35px" },
                        attrs: {
                          text: _vm.buttonText,
                          dark: false,
                          disabled: _vm.disabled,
                          className: "text-none",
                        },
                        on: { click: _vm.onClick },
                      })
                    : _vm._e(),
                  _vm._t("action-button"),
                ],
                2
              )
            : _vm._e(),
          _vm.subtitle
            ? _c("div", { staticClass: "body-2 mt-1 grey--text" }, [
                _vm._v(_vm._s(_vm.subtitle)),
              ])
            : _vm._e(),
          _c("div", { class: _vm.defaultSlotClass }, [_vm._t("default")], 2),
        ]
      ),
      _c("div", [_vm._t("footer")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }