var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.title
        ? _c(
            "div",
            { staticClass: "d-flex justify-space-between" },
            [
              _c("div", [
                _c("div", { staticClass: "subtitle-2" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _vm._t("action-button"),
            ],
            2
          )
        : _vm._e(),
      _vm.subtitle
        ? _c("div", { staticClass: "body-2 mt-1" }, [
            _vm._v(_vm._s(_vm.subtitle)),
          ])
        : _vm._e(),
      _c("div", { class: _vm.defaultSlotClass }, [_vm._t("default")], 2),
      _vm.isDividerShown ? _c("v-divider", { staticClass: "mt-4" }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }