var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pdf-container full-width d-flex flex-column align-center",
      class: _vm.wrapperPaddingClass,
    },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "pdf-loading d-flex flex-column align-center justify-center full-width",
            },
            [
              _c("v-progress-circular", {
                staticClass: "mb-4",
                attrs: {
                  rotate: -90,
                  size: 48,
                  value: _vm.percent,
                  width: 5,
                  color: "#0084FF",
                },
              }),
              _c("div", { staticClass: "text-subtitle-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.COURSE_SECTION_REPORT.MSG_DOWNLOADING"
                      )
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.errorMessage
        ? _c("div", { staticClass: "pdf-error" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
      _vm.pdfDoc
        ? _c(
            "div",
            { staticClass: "canvas-wrapper full-width full-height d-flex" },
            [
              _c("div", { staticClass: "wrapper-canvas-scroll ma-auto" }, [
                _c("canvas", { ref: "pdf-canvas", staticClass: "pdf-canvas" }),
              ]),
            ]
          )
        : _vm._e(),
      _vm.pdfDoc
        ? _c("div", { staticClass: "pdf-control-bar" }, [
            _c(
              "button",
              { staticClass: "small-padding", on: { click: _vm.prev } },
              [
                _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                  _vm._v("mdi-chevron-left"),
                ]),
              ],
              1
            ),
            _vm.isPageShown
              ? _c("div", { staticClass: "white--text mx-1 text-body-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.currentPage) +
                      "/" +
                      _vm._s(_vm.totalPage) +
                      " "
                  ),
                ])
              : _vm._e(),
            _c(
              "button",
              { staticClass: "small-padding", on: { click: _vm.next } },
              [
                _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                  _vm._v("mdi-chevron-right"),
                ]),
              ],
              1
            ),
            _c(
              "button",
              { staticClass: "small-padding", on: { click: _vm.zoomIn } },
              [
                _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                  _vm._v("mdi-plus"),
                ]),
              ],
              1
            ),
            _vm.isPercentShown
              ? _c("div", { staticClass: "white--text mx-1 text-body-1" }, [
                  _vm._v(" " + _vm._s((_vm.currentScale * 100) / 1) + "% "),
                ])
              : _vm._e(),
            _c(
              "button",
              { staticClass: "small-padding", on: { click: _vm.zoomOut } },
              [
                _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                  _vm._v("mdi-minus"),
                ]),
              ],
              1
            ),
            _vm.isDownloadShown
              ? _c(
                  "button",
                  {
                    staticClass: "small-padding",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("download")
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { color: "white", size: "30" } }, [
                      _vm._v("mdi-download"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }