var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "inspire" } },
    [
      _c(
        "v-app-bar",
        {
          staticClass: "d-flex justify-center",
          attrs: {
            app: "",
            absolute: "",
            "clipped-right": "",
            flat: "",
            height: "72",
          },
        },
        [
          _c(
            "v-responsive",
            { attrs: { "max-width": "300" } },
            [
              _c("ChangeResolution", {
                on: {
                  changeResolution: (value) => _vm.changeResolution(value),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        { attrs: { permanent: "", app: "", width: "500" } },
        [
          _c(
            "v-app-bar",
            {
              attrs: {
                absolute: "",
                "clipped-left": "",
                flat: "",
                height: "72",
              },
            },
            [_c("ActionsBar", { on: { onSave: _vm.onSave } })],
            1
          ),
          _c(
            "div",
            { staticClass: "d-flex flex-row full-height" },
            [
              _c(
                "v-navigation-drawer",
                {
                  attrs: {
                    permanent: "",
                    color: "grey darken-3",
                    width: "100",
                  },
                },
                [
                  _c("ListNavigations", {
                    attrs: {
                      onSave: _vm.onSave,
                      selected: _vm.selected,
                      checkChangedData: _vm.checkChangedData,
                    },
                    on: {
                      onSelectNavigation: (key) => _vm.onSelectNavigation(key),
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-navigation-drawer",
                { attrs: { permanent: "", width: "400" } },
                [
                  _c("EditDetailPart", {
                    ref: "edit-part",
                    attrs: {
                      selected: _vm.selected,
                      reloadIframe: _vm.reloadIframe,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        [
          _c("IFrameLearningPage", {
            ref: "iframe",
            attrs: {
              domain: `${_vm.learningDomain}` + `${_vm.path}`,
              resolution: _vm.resolution,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }