var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-file-name" }, [
    _c(
      "div",
      { staticClass: "text-title text-file" },
      [
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "span",
                      _vm._g(
                        _vm._b(
                          { staticClass: "tooltip-file-wrap" },
                          "span",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c("span", { staticClass: "ellipsis-one-line" }, [
                          _vm._v(_vm._s(_vm.getFileName(_vm.name).fullname)),
                        ]),
                      ]
                    ),
                  ]
                },
              },
            ]),
          },
          [
            _c("span", { staticClass: "black--text" }, [
              _vm._v(_vm._s(_vm.getFileName(_vm.name).fullname)),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }