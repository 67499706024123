var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        staticClass: "text-body-2",
        attrs: {
          items: _vm.items,
          "item-text": _vm.itemText,
          "item-value": _vm.itemValue,
          disabled: _vm.disabled,
          outlined: "",
          dense: "",
          multiple: _vm.multiple,
          label: _vm.label,
          placeholder: _vm.placeholder,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _vm.getValue(item) == _vm.actionCustomValue
                    ? [
                        _vm._t("action-custom"),
                        !_vm.$slots["action-custom"]
                          ? [_vm._v(" " + _vm._s(_vm.getText(item)) + " ")]
                          : _vm._e(),
                      ]
                    : [_vm._v(" " + _vm._s(_vm.getText(item)) + " ")],
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.tempValue,
          callback: function ($$v) {
            _vm.tempValue = $$v
          },
          expression: "tempValue",
        },
      },
      "v-select",
      _vm.$attrs,
      false
    ),
    [
      _c("template", { slot: "label" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.label)),
          _vm.isRequiredLabel
            ? _c("strong", { staticClass: "red--text ml-1" }, [_vm._v("* ")])
            : _vm._e(),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }