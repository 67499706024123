var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_SEND_NOTIFICATION_TITLE"
                  )
                ) +
                " "
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pt-5 pb-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form-send-notification",
                  model: {
                    value: _vm.form,
                    callback: function ($$v) {
                      _vm.form = $$v
                    },
                    expression: "form",
                  },
                },
                [
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_NOTIFICATION_HEADER_LABEL"
                      ),
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_INPUT_NOTIFICATION_HEADER_PLACEHOLDER"
                      ),
                      rules: [_vm.$rules.required],
                    },
                    model: {
                      value: _vm.title,
                      callback: function ($$v) {
                        _vm.title = $$v
                      },
                      expression: "title",
                    },
                  }),
                  _c("BaseInput", {
                    attrs: {
                      label: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_NOTIFICATION_CONTENT_LABEL"
                      ),
                      placeholder: _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_INPUT_NOTIFICATION_CONTENT_PLACEHOLDER"
                      ),
                      rules: [_vm.$rules.required],
                    },
                    model: {
                      value: _vm.content,
                      callback: function ($$v) {
                        _vm.content = $$v
                      },
                      expression: "content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "py-4" },
            [
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  isButtonText: "",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.BUTTON_CANCEL_TEXT"
                  ),
                },
                on: { click: _vm.closeDialog },
              }),
              _c("BaseButton", {
                attrs: {
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.STUDENT_LIST.POPUP_SEND_NOTIFICATION_BUTTON"
                  ),
                  dark: false,
                  disabled: !_vm.form,
                },
                on: { click: _vm.onSendNotification },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }