import { render, staticRenderFns } from "./BaseEditor.vue?vue&type=template&id=b07b6bbe&scoped=true&"
import script from "./BaseEditor.vue?vue&type=script&lang=js&"
export * from "./BaseEditor.vue?vue&type=script&lang=js&"
import style0 from "./BaseEditor.vue?vue&type=style&index=0&id=b07b6bbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b07b6bbe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b07b6bbe')) {
      api.createRecord('b07b6bbe', component.options)
    } else {
      api.reload('b07b6bbe', component.options)
    }
    module.hot.accept("./BaseEditor.vue?vue&type=template&id=b07b6bbe&scoped=true&", function () {
      api.rerender('b07b6bbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/global/BaseEditor.vue"
export default component.exports