var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-width d-flex justify-space-between align-center" },
    [
      _c("BaseTitleTable", {
        attrs: {
          isBack: "",
          backText: _vm.$vuetify.lang.t(
            "$vuetify.SITE_BUILDER.ACTION_BAR.GO_BACK_TEXT"
          ),
        },
        on: { click: _vm.openPopupConfirm },
      }),
      _c("BaseButton", {
        attrs: {
          text: _vm.$vuetify.lang.t("$vuetify.SITE_BUILDER.BUTTON_SAVE"),
          loading: _vm.loading,
        },
        on: {
          click: function ($event) {
            return _vm.$emit("onSave")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }