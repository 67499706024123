var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c(
        "transition",
        {
          attrs: {
            appear: "",
            "enter-active-class": "animated slideInUp",
            "leave-active-class": "animated fadeOut",
          },
        },
        [_c("router-view")],
        1
      ),
      _c("BaseMessage"),
      _c("BaseConfirm"),
      _c("BaseConfirmSupport"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }