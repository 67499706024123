var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.pageOnEdit && _vm.pageOnEdit.isAddition
      ? _c(
          "div",
          [
            _c("div", { staticClass: "d-flex" }, [
              _c("div", { staticClass: "list-sections-title" }, [
                _vm._v(" " + _vm._s("Tên trang:") + " "),
              ]),
              _c("div", { staticClass: "pl-2 list-sections-title--content" }, [
                _vm._v(" " + _vm._s(`${_vm.pageOnEdit.title}`) + " "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c("div", { staticClass: "list-sections-title" }, [
                  _vm._v(_vm._s("Url:")),
                ]),
                _c(
                  "div",
                  { staticClass: "pl-2 list-sections-title--content" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$utils.formatTitle(
                            `/custom-page/${_vm.pageOnEdit.url}`,
                            35
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.copyText(
                          `/custom-page/${_vm.pageOnEdit.url}`
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mb-2", attrs: { size: "16" } },
                      [_vm._v("mdi-content-copy")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mb-4" }),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "slick-list" },
      [
        _c(
          "SlickList",
          {
            attrs: {
              lockAxis: "y",
              appendTo: ".slick-list",
              lockToContainerEdges: "",
            },
            on: { "sort-end": (event) => _vm.$emit("sort-end", event) },
            model: {
              value: _vm.sectionNames,
              callback: function ($$v) {
                _vm.sectionNames = $$v
              },
              expression: "sectionNames",
            },
          },
          _vm._l(_vm.sectionNames, function (item, i) {
            return _c(
              "SlickItem",
              {
                key: i,
                staticClass: "section-item",
                attrs: { index: i, disabled: _vm.isSlickDisabled },
              },
              [
                _c("v-icon", { staticClass: "section-item__icon" }, [
                  _vm._v("mdi-drag-vertical"),
                ]),
                _c("v-icon", { staticClass: "mr-2" }, [
                  _vm._v("mdi-image-area"),
                ]),
                _c("div", { staticClass: "full-width subheading" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.generateNameSection(
                          item,
                          _vm.isMobile ? _vm.isMobile : false
                        )
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "v-icon",
                  {
                    staticClass: "section-item__icon mr-2",
                    on: {
                      click: function ($event) {
                        return _vm.editSection(item)
                      },
                    },
                  },
                  [_vm._v("mdi-cog-outline")]
                ),
                _c(
                  "v-icon",
                  {
                    staticClass: "section-item__icon mr-2 action-delete-hover",
                    on: {
                      click: function ($event) {
                        return _vm.removeItem(item)
                      },
                    },
                  },
                  [_vm._v("mdi-delete")]
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "mt-4 d-flex justify-center",
        class: { "justify-space-between": _vm.pageOnEdit?.isAddition },
      },
      [
        _c("BaseButton", {
          attrs: {
            icon: "mdi-plus",
            text: _vm.$vuetify.lang.t(
              "$vuetify.SITE_BUILDER.DETAIL.BTN_ADD_COMPONENT"
            ),
            outlined: "",
          },
          on: { click: _vm.addSection },
        }),
        _vm.pageOnEdit?.isAddition
          ? _c("BaseButton", {
              attrs: {
                icon: "mdi-delete",
                text: "Xoá trang",
                outlined: "",
                color: "error",
              },
              on: { click: () => _vm.deletePage() },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }