var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-time-picker" },
    [
      _c(
        "v-menu",
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            "nudge-right": 40,
            "return-value": _vm.time,
            transition: "scale-transition",
            "offset-y": "",
            "max-width": "290px",
            "min-width": "290px",
          },
          on: {
            "update:returnValue": function ($event) {
              _vm.time = $event
            },
            "update:return-value": function ($event) {
              _vm.time = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-text-field",
                    _vm._g(
                      {
                        attrs: {
                          "append-icon": "mdi-clock-outline",
                          readonly: "",
                          label: _vm.label,
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.time,
                          callback: function ($$v) {
                            _vm.time = $$v
                          },
                          expression: "time",
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm.menu
            ? _c("v-time-picker", {
                attrs: { "full-width": "" },
                on: {
                  "click:minute": function ($event) {
                    return _vm.$refs.menu.save(_vm.time)
                  },
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }