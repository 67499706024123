var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("BaseTitleTable", {
        attrs: {
          isBack: "",
          backText: _vm.$vuetify.lang.t(
            "$vuetify.SITE_BUILDER.DETAIL.GO_BACK_TEXT"
          ),
          textClass: "subtitle-blur action-hover",
        },
        on: { click: _vm.backToListSection },
      }),
      _c("div", { staticClass: "text-h6 font-weight-medium mb-3" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$vuetify.lang.t(
                "$vuetify.SITE_BUILDER.DETAIL.BTN_ADD_COMPONENT"
              )
            ) +
            " "
        ),
      ]),
      _c(
        "v-expansion-panels",
        { attrs: { flat: "", accordion: "", hover: "" } },
        _vm._l(_vm.listSelections, function (section, i) {
          return _c(
            "v-expansion-panel",
            { key: i },
            [
              _c("v-expansion-panel-header", [
                _vm._v(_vm._s(_vm.$vuetify.lang.t(section.name)) + " "),
              ]),
              _c(
                "v-expansion-panel-content",
                _vm._l(section.children, function (child, j) {
                  return _c(
                    "div",
                    {
                      key: j,
                      staticClass: "mt-8 d-flex align-start cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.selectedItem(child)
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-4" }, [
                        _vm._v("mdi-image-area"),
                      ]),
                      _c("div", { staticClass: "full-width mr-3" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$vuetify.lang.t(child.label))),
                        ]),
                        _c("div", { staticClass: "caption" }, [
                          _vm._v(
                            _vm._s(_vm.$vuetify.lang.t(child.description))
                          ),
                        ]),
                      ]),
                      _vm.selected.name === child.name
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                depressed: "",
                                color: "primary",
                                dark: "",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onAddSection(child)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$vuetify.lang.t(
                                      "$vuetify.SITE_BUILDER.BUTTON_ADD"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }