var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "620px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline lighten-2",
              attrs: { "primary-titledialog": "" },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$vuetify.lang.t(
                    _vm.type === "email"
                      ? "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP_EMAIL_ERROR"
                      : "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.TITLE_POPUP_NOTIFICATION_ERROR"
                  )
                ) + " "
              ),
              _c("v-spacer"),
              _c(
                "v-icon",
                { on: { click: () => (_vm.dialog = !_vm.dialog) } },
                [_vm._v(" mdi-close ")]
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "my-3 rounded-ts-xl",
                attrs: {
                  "disable-sort": "",
                  headers: _vm.headers,
                  items: _vm.students,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.student`,
                      fn: function ({ item }) {
                        return [_vm._v(" " + _vm._s(item.student) + " ")]
                      },
                    },
                    {
                      key: `item.email`,
                      fn: function ({ item }) {
                        return [_vm._v(" " + _vm._s(item.email) + " ")]
                      },
                    },
                    {
                      key: `item.problem`,
                      fn: function ({ item }) {
                        return [_vm._v(" " + _vm._s(item.problem) + " ")]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "py-4" },
            [
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  outlined: "",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_EXPORT_FILE_EXCEL"
                  ),
                },
                on: {
                  click: function ($event) {
                    _vm.dialog = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }