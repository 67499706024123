var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-divider", { staticClass: "mt-4 mb-2" }),
      _c(
        "div",
        {
          staticStyle: {
            "font-size": "larger",
            "font-weight": "600",
            "margin-bottom": "5px",
          },
        },
        [_vm._v("Cài đặt SEO")]
      ),
      _c(
        "BaseBuilderWrapper",
        { attrs: { title: "Tiêu đề trang", isDividerShown: false } },
        [
          _c("BaseInput", {
            attrs: { label: "Tiêu đề trang" },
            model: {
              value: _vm.seo.seoTitle,
              callback: function ($$v) {
                _vm.$set(_vm.seo, "seoTitle", $$v)
              },
              expression: "seo.seoTitle",
            },
          }),
        ],
        1
      ),
      _c(
        "BaseBuilderWrapper",
        { attrs: { title: "Mô tả trang", isDividerShown: false } },
        [
          _c("BaseTextArea", {
            attrs: { label: "Mô tả trang" },
            model: {
              value: _vm.seo.seoDescription,
              callback: function ($$v) {
                _vm.$set(_vm.seo, "seoDescription", $$v)
              },
              expression: "seo.seoDescription",
            },
          }),
        ],
        1
      ),
      _c(
        "BaseBuilderWrapper",
        {
          attrs: {
            title: _vm.$vuetify.lang.t("Ảnh"),
            subtitle: _vm.$vuetify.lang.t(
              "$vuetify.SITE_BUILDER.SITE_INFO.THUMBNAIL_DESCRIPTION"
            ),
            isDividerShown: false,
          },
        },
        [
          _c("BaseUploadImage", {
            attrs: { type: "image", imageUrl: _vm.seo.seoImage },
            on: {
              onUploadSuccess: (data) => _vm.onUploadSuccess(data, "seoImage"),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }