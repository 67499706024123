var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseUploadDragger",
    {
      attrs: {
        type: _vm.type,
        maxFilesize: _vm.maxFilesize,
        relation: _vm.relation,
        "max-files": _vm.maxFiles,
        "upload-multiple": _vm.uploadMultiple,
        singleUploadFileSize: _vm.singleUploadFileSize,
        "is-confirm-popup-used": _vm.isConfirmPopupUsed,
      },
      on: { onUploadSuccess: (data) => _vm.onUploadSuccess(data) },
    },
    [
      _c(
        "div",
        {
          staticClass: "has-dashed full-width d-flex justify-center",
          attrs: { id: "wrapper" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-center" },
            [
              _c(
                "div",
                {
                  staticClass: "subtitle-1 font-weight-bold text-align-center",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.COURSE.COURSE_OVERVIEW.TITLE_DRAG_DROP"
                        )
                      ) +
                      "... "
                  ),
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "subtitle-2 mt-2 text-capitalize",
                  attrs: { rounded: "", color: "primary" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$vuetify.lang.t(
                          "$vuetify.COURSE.COURSE_OVERVIEW.BUTOTN_SELECT_FILE_IN_MACHINE"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }