var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cursor-pointer relative" },
    [
      _c("div", { staticClass: "absolute label-text-area" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("div", {
        staticClass: "text-area",
        domProps: { innerHTML: _vm._s(_vm.html) },
        on: {
          click: function ($event) {
            _vm.isOpenEditorDialog = true
          },
        },
      }),
      _c("EditorDialog", {
        attrs: { content: _vm.html, isOpen: _vm.isOpenEditorDialog },
        on: {
          close: function ($event) {
            _vm.isOpenEditorDialog = false
          },
          accept: _vm.handleAccept,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }