var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column justify-space-between" },
    [
      _vm.listImagesWithIcons.length
        ? _c("div", { staticStyle: { "min-height": "300px" } }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap",
                staticStyle: { "min-height": "300px" },
              },
              _vm._l(_vm.listImagesWithIcons, function (image) {
                return _c("BaseImageThumbnail", {
                  key: image.id,
                  staticClass: "mr-3 mb-3",
                  attrs: {
                    link: _vm.$utils.convertURL(
                      _vm.$utils.combineLinkWithBucket(image.link)
                    ),
                    isShowIcon: image.isShowIcon,
                  },
                  on: {
                    onSelect: function ($event) {
                      return _vm.onSelect(image)
                    },
                  },
                })
              }),
              1
            ),
          ])
        : _c(
            "div",
            { staticClass: "d-flex", staticStyle: { "min-height": "300px" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$vuetify.lang.t(
                      "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.LIST_IMAGE_THUMBNAIL.TITLE_NOT_FOUND_IMAGE"
                    )
                  ) +
                  " "
              ),
            ]
          ),
      _vm.listImagesWithIcons.length
        ? _c(
            "div",
            { staticClass: "d-flex justify-space-between full-width" },
            [
              _c("div", { staticClass: "mt-3" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.LIST_IMAGE_THUMBNAIL.LABEL_PAGE"
                      )
                    ) +
                    " " +
                    _vm._s(_vm.pageNumber) +
                    " - " +
                    _vm._s(_vm.total) +
                    " "
                ),
              ]),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: _vm.pageNumber === 1, icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("previousPage")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.pageNumber === _vm.total,
                        icon: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("nextPage")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }