var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "outlined" },
    [
      _c(
        "v-autocomplete",
        _vm._b(
          {
            staticClass: "body-2",
            attrs: {
              items: _vm.items,
              loading: _vm.isLoading,
              "search-input": _vm.search,
              label: _vm.$vuetify.lang.t(
                "$vuetify.MANAGE_FINANCE.POPUP_ADD_PROGRAM.LABEL_FILTER_COURSE"
              ),
              placeholder: _vm.$vuetify.lang.t(
                "$vuetify.MANAGE_FINANCE.POPUP_ADD_PROGRAM.PLACEHOLDER_FILTER_COURSE"
              ),
              "item-text": _vm.itemText,
              "item-value": _vm.itemValue,
              "return-object": "",
              dense: "",
              outlined: "",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.search = $event
              },
              "update:search-input": function ($event) {
                _vm.search = $event
              },
              change: (value) => _vm.$emit("change", value),
            },
          },
          "v-autocomplete",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }