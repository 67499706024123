var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        staticClass: "body-2",
        attrs: {
          items: _vm.items,
          rules: _vm.rules,
          chips: "",
          multiple: "",
          outlined: "",
          placeholder: _vm.$vuetify.lang.t(
            "$vuetify.USER_MANAGE.STUDENT_LIST.LABEL_SELECT_INTEREST"
          ),
          dense: "",
          "item-text": "title",
          "return-object": "",
        },
        on: { change: (value) => _vm.$emit("change", value) },
        model: {
          value: _vm.selection,
          callback: function ($$v) {
            _vm.selection = $$v
          },
          expression: "selection",
        },
      },
      "v-select",
      this.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }