var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisibility,
          expression: "isVisibility",
        },
      ],
    },
    [
      _c(
        "div",
        { key: _vm.rendererTime, attrs: { id: "pdf-renderer-exam" } },
        [
          _c("StudentInfo"),
          _vm._l(_vm.flattenQuestions, function (question, index) {
            return _c(
              "div",
              { key: index, staticClass: "question-container" },
              [
                question.type === "direct-fill-blank-group"
                  ? _c(
                      "div",
                      [
                        _c("QuestionDirectFillGroup", {
                          attrs: { question: question, index: index },
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _c(
                        "div",
                        { staticClass: "question-overview" },
                        [
                          question.parentHtml
                            ? _c(
                                "div",
                                { staticClass: "question-overview__parent" },
                                _vm._l(
                                  question.parentHtml,
                                  function (element, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: "parent" + i,
                                        staticClass:
                                          "question-overview__description canvas-element-exam",
                                        class: {
                                          "is-last":
                                            i ===
                                            question.parentHtml.length - 1,
                                        },
                                      },
                                      [
                                        i === 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "question-overview__parent-title",
                                              },
                                              [_vm._v("Câu hỏi nhóm")]
                                            )
                                          : _vm._e(),
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(element),
                                          },
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                          _vm._l(question.description, function (element, i) {
                            return _c(
                              "div",
                              {
                                key: "description" + i,
                                staticClass:
                                  "question-overview__description canvas-element-exam",
                              },
                              [
                                i === 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "question-overview__title",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$vuetify.lang.t(
                                                "$vuetify.COURSE.BASE_PDF_QUESTION_RENDERRE.LABEL_QUESTION"
                                              )
                                            ) +
                                            " " +
                                            _vm._s(question.index + 1) +
                                            ": " +
                                            _vm._s(
                                              _vm.generateType(question.type)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", {
                                  domProps: { innerHTML: _vm._s(element) },
                                }),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      question.type !== "group"
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "question-container__answer canvas-element-exam",
                              class: { "is-last": question.isLastQuestion },
                            },
                            [_c("Question", { attrs: { question: question } })],
                            1
                          )
                        : _vm._e(),
                    ]),
              ]
            )
          }),
        ],
        2
      ),
      _c("div", { attrs: { id: "pdf-renderer-temp-exam" } }),
      _c("div", { attrs: { id: "description" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }