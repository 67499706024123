var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "620px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "headline lighten-2",
              attrs: { "primary-titledialog": "" },
            },
            [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _c("v-spacer"),
              _c(
                "v-icon",
                { on: { click: () => (_vm.dialog = !_vm.dialog) } },
                [_vm._v(" mdi-close ")]
              ),
            ],
            1
          ),
          _c("v-card-text", [
            _c(
              "p",
              {
                staticClass: "font-weight-medium my-1",
                staticStyle: { color: "#3e3e3c" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.NOTIFICATION_TO"
                      )
                    ) +
                    " "
                ),
                _c("span", { staticStyle: { color: "#255cc2" } }, [
                  _vm._v(_vm._s(_vm.name)),
                ]),
              ]
            ),
            _c("p", { staticClass: "my-1" }, [_vm._v(_vm._s(_vm.email))]),
            _c("p", { staticClass: "my-3" }, [
              _c("span", { staticStyle: { color: "#4bca81" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.NOTIFICATION_SUCCESS"
                      )
                    ) +
                    " "
                ),
              ]),
              _vm._v(" vào "),
              _c("span", [_vm._v(_vm._s(_vm.date))]),
            ]),
            _c("p", { domProps: { innerHTML: _vm._s(_vm.content) } }),
            _vm.linkWeb
              ? _c("p", { staticClass: "my-1" }, [
                  _vm._v(" Web "),
                  _c("a", { staticStyle: { color: "#255cc2" } }, [
                    _vm._v(_vm._s(_vm.linkWeb)),
                  ]),
                ])
              : _vm._e(),
            _vm.linkMobile
              ? _c("p", { staticClass: "my-1" }, [
                  _vm._v(" Mobile App "),
                  _c("a", { staticStyle: { color: "#255cc2" } }, [
                    _vm._v(_vm._s(_vm.linkMobile)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "v-card-actions",
            { staticClass: "py-4" },
            [
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  outlined: "",
                  text: _vm.$vuetify.lang.t(
                    "$vuetify.USER_MANAGE.GROUP_STUDENT_DETAIL.BUTTON_CLOSE"
                  ),
                },
                on: {
                  click: function ($event) {
                    _vm.dialog = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }