var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "650", persistent: _vm.isConfirm },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "base-upload-dragger-list" },
        [
          _c(
            "v-card-title",
            { staticClass: "headline pa-2" },
            [
              _c("div", { staticClass: "ml-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE_CONTENT.LIBRARY_LIST.VIDEO_PANEL.CONFIRM_UPLOAD_MULTIPLE_FILES"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.closePopup } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "pa-4" }, [
            _c(
              "div",
              { staticClass: "upload-wrapper" },
              _vm._l(_vm.dataItems, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "upload-file-wrapper pa-2 full-width",
                  },
                  [
                    _c("div", { staticClass: "upload-file-wrapper__info" }, [
                      _c("div", { staticClass: "full-width" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "upload-file-wrapper__info-name text-body-2",
                            class: {
                              "upload-file-wrapper__info-oversize":
                                item.originSize >
                                _vm.singleUploadFileSize * 1024 * 1024, //2.5*1024*1024*1024 = 2,5gb
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "upload-file-wrapper__info-name--title",
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                            item.originSize >
                            _vm.singleUploadFileSize * 1024 * 1024
                              ? _c(
                                  "div",
                                  { staticClass: "pl-1" },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "error",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " mdi-close-circle-outline "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "black--text text-justify",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `File không được vượt quá ${_vm.singleUploadFileSize} MB`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "upload-file-wrapper__info-oversize--re-upload",
                                        on: {
                                          click: function ($event) {
                                            return _vm.reUploadImage(index)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s("Chọn lại File") + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "upload-file-wrapper__action" }, [
                      !_vm.isConfirm
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "upload-file-wrapper__action-stat",
                              },
                              [
                                _c("div", { staticClass: "text-body-2" }, [
                                  _vm._v(" " + _vm._s(item.size) + " "),
                                ]),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: () => _vm.onRemoveFile(index),
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { color: "red" } }, [
                                      _vm._v("mdi-delete"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ])
                        : _c("div", [
                            item.percent > 0 && item.percent !== 100
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "upload-file-wrapper__action-loading",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "upload-file-wrapper__action-loading--percent text-body-2",
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.percent) + "% "
                                        ),
                                      ]
                                    ),
                                    _c("BaseLoading"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.percent === 100
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("mdi-check-circle-outline")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "pa-4" },
            [
              _c("BaseButton", {
                attrs: {
                  text: _vm.$vuetify.lang.t("$vuetify.BUTTON_CANCEL"),
                  outlined: "",
                  loading: _vm.isConfirm,
                },
                on: { click: _vm.closePopup },
              }),
              _c("v-spacer"),
              _c("BaseButton", {
                attrs: {
                  text: _vm.$vuetify.lang.t("$vuetify.BUTTON_CONFIRM"),
                  loading: _vm.dataItems.length !== 0 && _vm.isConfirm,
                  disabled: _vm.dataItems.length === 0,
                },
                on: { click: _vm.onConfirm },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }