var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "full-width d-flex align-center" },
    [
      _c(
        "v-icon",
        {
          staticClass: "cursor-pointer",
          attrs: {
            medium: "",
            color: _vm.resolution === "mobile" ? "primary" : "grey lighten-2",
          },
          on: {
            click: function ($event) {
              return _vm.changeResolution("mobile", "400px")
            },
          },
        },
        [_vm._v("mdi-cellphone-iphone")]
      ),
      _c(
        "v-icon",
        {
          staticClass: "mr-4 ml-4 cursor-pointer",
          attrs: {
            color: _vm.resolution === "tablet" ? "primary" : "grey lighten-2",
            medium: "",
          },
          on: {
            click: function ($event) {
              return _vm.changeResolution("tablet", "800px")
            },
          },
        },
        [_vm._v("mdi-tablet-ipad")]
      ),
      _c(
        "v-icon",
        {
          staticClass: "cursor-pointer",
          attrs: {
            medium: "",
            color: _vm.resolution === "desktop" ? "primary" : "grey lighten-2",
          },
          on: {
            click: function ($event) {
              return _vm.changeResolution("desktop", "100%")
            },
          },
        },
        [_vm._v("mdi-laptop-mac")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }