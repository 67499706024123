var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-logo pa-3", attrs: { to: _vm.noHref ? "" : "/" } },
    [
      _c("img", {
        attrs: { src: "/admin-static/iz-teach-logo.jpg", alt: "logo" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }