var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            color: "primary",
            dark: "",
            permanent: "",
            fixed: "",
            touchless: "",
            app: "",
            width: "328",
            "mini-variant-width": "68",
            "mini-variant": _vm.isMinimized,
            "mobile-breakpoint": 600,
          },
          on: {
            "update:miniVariant": function ($event) {
              _vm.isMinimized = $event
            },
            "update:mini-variant": function ($event) {
              _vm.isMinimized = $event
            },
          },
        },
        [
          _c(
            "v-list",
            { staticClass: "pt-0" },
            [
              _c(
                "v-list-item",
                { on: { click: _vm.onRedirect } },
                [
                  _c(
                    "v-list-item-icon",
                    { class: { "mr-4": !_vm.isMinimized } },
                    [
                      _c("img", {
                        attrs: {
                          src: "/admin-static/navigation-bar-icon/view-dashboard.svg",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-list-item-title",
                    { staticClass: "text-uppercase font-weight-bold" },
                    [_vm._v(" " + _vm._s(_vm.siteName) + " ")]
                  ),
                  _c(
                    "v-list-item-action",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.isMinimized = !_vm.isMinimized
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-chevron-double-left")])],
                    1
                  ),
                ],
                1
              ),
              _vm._l(_vm.filteredItemsByRole, function (item, i) {
                return _c(
                  "v-list-group",
                  {
                    key: i,
                    attrs: {
                      "active-class": `white--text ${
                        _vm.isMinimized ? "is-minimized" : ""
                      }`,
                      "no-action": "",
                      to: item.path,
                      ripple: false,
                      value: _vm.menuSectionIndex === i,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.setMenuSectionIndex(i)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-list-item-icon",
                                { class: { "mr-4": !_vm.isMinimized } },
                                [
                                  item.icon.startsWith("/")
                                    ? _c("img", { attrs: { src: item.icon } })
                                    : _c(
                                        "v-icon",
                                        { staticClass: "account-layout__icon" },
                                        [_vm._v(_vm._s(item.icon))]
                                      ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    {
                                      staticClass:
                                        "text-uppercase font-weight-medium",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$vuetify.lang.t(item.name)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c(
                      "transition-group",
                      {
                        key: `key-${i}`,
                        attrs: { name: "fade", mode: "in-out", appear: "" },
                      },
                      [
                        _vm._l(item.children, function (child, j) {
                          return [
                            child.hasTopDivider
                              ? _c("v-divider", {
                                  key: `divider-key-${i}-${j}`,
                                  staticClass:
                                    "ml-13 mr-4 animate__animated animate__slideInLeft",
                                })
                              : _vm._e(),
                            _c(
                              "v-list-item",
                              {
                                key: `item-key-${i}-${j}`,
                                staticClass:
                                  "pl-14 animate__animated animate__slideInLeft",
                                attrs: {
                                  "active-class": "primary--text white",
                                  to: child.path,
                                  ripple: false,
                                },
                              },
                              [
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "user-select-none" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$vuetify.lang.t(child.name)
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-list-item",
                { on: { click: _vm.onSignOut } },
                [
                  _c(
                    "v-list-item-icon",
                    { class: { "mr-4": !_vm.isMinimized } },
                    [_c("v-icon", [_vm._v("mdi-logout-variant")])],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "text-uppercase font-weight-medium" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$vuetify.lang.t(
                                  "$vuetify.ROUTE.NAVIGATION_ROUTES.SIGN_OUT"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isMinimized
                ? _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isMinimized = !_vm.isMinimized
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-chevron-double-right")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("v-main", [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }