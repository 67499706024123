var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message.isDisplay
    ? _c(
        "BaseLoadingAllScreen",
        { attrs: { customDisplay: "", freeze: _vm.message.color === "white" } },
        [
          _c(
            "div",
            { attrs: { slot: "object-inside" }, slot: "object-inside" },
            [
              _c(
                "v-snackbar",
                {
                  attrs: {
                    bottom: _vm.y === "bottom",
                    color: _vm.message.color,
                    elevation: 5,
                    left: _vm.x === "left",
                    "multi-line": _vm.mode === "multi-line",
                    right: _vm.x === "right",
                    timeout: _vm.message.timeout ? _vm.message.timeout : 750,
                    top: _vm.y === "top",
                    vertical: _vm.mode === "vertical",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "action",
                        fn: function ({ attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._b(
                                {
                                  staticClass: "mr-2",
                                  attrs: { elevation: "0", icon: "" },
                                  on: { click: _vm.close },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              [_c("v-icon", [_vm._v("mdi-close")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2347111207
                  ),
                  model: {
                    value: _vm.message.isDisplay,
                    callback: function ($$v) {
                      _vm.$set(_vm.message, "isDisplay", $$v)
                    },
                    expression: "message.isDisplay",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-inline-flex pa-0" },
                    [
                      _vm.message.color === "white"
                        ? _c("v-progress-circular", {
                            staticClass: "mr-3",
                            attrs: {
                              indeterminate: "",
                              color: "primary",
                              size: 20,
                              width: 1,
                            },
                          })
                        : _vm._e(),
                      _c("span", {
                        class: `${
                          _vm.message.color === "white"
                            ? "primary--text"
                            : "white--text"
                        }`,
                        domProps: { innerHTML: _vm._s(_vm.message.text) },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }