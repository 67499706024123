var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-textarea",
    _vm._b(
      {
        staticClass: "body-2",
        attrs: {
          outlined: "",
          dense: "",
          label: _vm.label,
          rules: _vm.rules,
          type: _vm.type,
          placeholder: _vm.placeholder,
          hint: _vm.hint,
          "persistent-hint": _vm.persistentHint,
          rows: _vm.row,
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm.label)),
                  _vm.isRequiredTitle
                    ? _c("strong", { staticClass: "red--text ml-1" }, [
                        _vm._v("* "),
                      ])
                    : _vm._e(),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.data,
          callback: function ($$v) {
            _vm.data = $$v
          },
          expression: "data",
        },
      },
      "v-textarea",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }