var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass:
        "black--text cursor-pointer body-2 font-weight-medium action-hover",
      attrs: { target: "_blank" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _vm._t("default"),
      _vm.isBack
        ? _c(
            "div",
            { staticClass: "d-flex align-center", class: _vm.textClass },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { size: "18" } }, [
                _vm._v("mdi-arrow-left"),
              ]),
              _vm._v(" " + _vm._s(_vm.backText) + " "),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }