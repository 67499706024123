var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative wrapper", on: { click: _vm.onSelect } },
    [
      _c(
        "div",
        { staticClass: "relative box-thumbnail" },
        [
          !_vm.isloading
            ? _c("img", {
                staticClass: "image-inside",
                attrs: { src: _vm.link },
              })
            : _c("v-progress-circular", {
                staticClass: "mt-12",
                attrs: { indeterminate: "", color: "white" },
              }),
        ],
        1
      ),
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShowIcon,
              expression: "isShowIcon",
            },
          ],
          staticClass: "checkbox",
          attrs: { dense: "", large: "", color: "primary", right: "" },
        },
        [_vm._v(" mdi-checkbox-marked-circle ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }