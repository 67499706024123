var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectedImage
    ? _c(
        "div",
        [
          _c("h2", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$vuetify.lang.t(
                    "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.TITLE"
                  )
                ) +
                " "
            ),
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex justify-center",
                  attrs: { cols: "12", md: "5" },
                },
                [
                  !_vm.isLoading
                    ? _c("img", {
                        staticClass: "thumbnail",
                        attrs: {
                          src: _vm.$utils.convertURL(
                            _vm.$utils.combineLinkWithBucket(
                              _vm.selectedImage.link
                            )
                          ),
                        },
                      })
                    : _c("v-progress-circular", {
                        staticClass: "mt-8",
                        attrs: { indeterminate: "", color: "gray" },
                      }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12", md: "7" } }, [
                _c(
                  "div",
                  { staticClass: "font-weight-bold ellipsis-one-line" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm.selectedImage && _vm.selectedImage.title) ||
                            "Untitled"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "ellipsis-one-line" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_SIZE"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.size)
                  ),
                ]),
                _c("div", { staticClass: "ellipsis-one-line" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_CREATE_AT"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.createdAt)
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "primary--text cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.editImage()
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$vuetify.lang.t(
                            "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.BUTTON_EDIT_IMAGE_INFO"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "green--text" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_FORMAT"
                      )
                    ) +
                      ": " +
                      _vm._s(_vm.mimeType)
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("v-divider"),
          _c("BaseInput", {
            staticClass: "mr-2 mt-5",
            attrs: {
              name: "url",
              label: "URL",
              placeholder: _vm.$vuetify.lang.t(
                "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.PLACEHOLDER_INPUT_LINK"
              ),
              disabled: "",
              value: _vm.url,
            },
          }),
          _c("BaseInput", {
            staticClass: "mr-2",
            attrs: {
              name: "title",
              label: _vm.$vuetify.lang.t(
                "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.LABEL_HEADER"
              ),
              placeholder: _vm.$vuetify.lang.t(
                "$vuetify.COURSE.BASE_UPLOAD_IMAGE.MODAL_LIBRARY.ATTACHMENT_DETAIL.PLACEHOLDER_INPUT_LINK"
              ),
            },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }