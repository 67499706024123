var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("BaseResourceAutoComplete", {
            staticClass: "full-width",
            attrs: {
              hideDetails: "",
              label: _vm.$vuetify.lang.t(
                "$vuetify.COURSE.COURSE_OVERVIEW.LABEL_SELECT_VIDEO_FROM_LIBRARY"
              ),
              filter: _vm.filterResource(),
            },
            model: {
              value: _vm.resource,
              callback: function ($$v) {
                _vm.resource = $$v
              },
              expression: "resource",
            },
          }),
          _vm.isResourceDownloadAllowed &&
          _vm.resource &&
          (_vm.type === "pdf" || _vm.type === "audio")
            ? _c(
                "v-tooltip",
                {
                  attrs: { color: "grey darken-3", top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2 action-hover rounded",
                                    attrs: { icon: "", outlined: "" },
                                    on: { click: _vm.onDownloadResource },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-download")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3858645649
                  ),
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$vuetify.lang.t("$vuetify.COURSE.BUTTON_DOWNLOAD")
                      )
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.type === "video" && _vm.resource
        ? _c(
            "div",
            { staticClass: "mt-2 d-flex align-center" },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$vuetify.lang.t(
                      "$vuetify.COURSE.COURSE_OVERVIEW.LABEL_STATUS_VIDEO"
                    )
                  ) + ":"
                ),
              ]),
              _c(
                "span",
                {
                  class:
                    _vm.videoStatus === "done" ? "green--text" : "red--text",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.videoStatus === "done"
                          ? _vm.$vuetify.lang.t(
                              "$vuetify.COURSE.COURSE_OVERVIEW.TEXT_READY"
                            )
                          : _vm.$vuetify.lang.t(
                              "$vuetify.COURSE.COURSE_OVERVIEW.TEXT_VIDEO_PROCESSED"
                            )
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.videoStatus !== "done"
                ? _c(
                    "v-icon",
                    {
                      staticClass: "ml-2",
                      class: { spin: _vm.loadingStatus },
                      on: {
                        click: function ($event) {
                          return _vm.getvideoStatus(_vm.resource)
                        },
                      },
                    },
                    [_vm._v(" mdi-autorenew ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("BaseUploadWithButton", {
        staticClass: "mt-2",
        attrs: {
          maxFilesize: _vm.maxFilesize,
          type: _vm.type,
          relation: _vm.relation,
        },
        on: { onUploadSuccess: (file) => _vm.onUploadSuccess(file) },
      }),
      _vm.type === "video" && _vm.resource
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("div", { staticClass: "subtitle-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.COURSE_OVERVIEW.TITLE_VIDEO_THUMBNAIL"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "body-2", attrs: { "mt-1": "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$vuetify.lang.t(
                        "$vuetify.COURSE.COURSE_OVERVIEW.SUBTITLE_VIDEO_THUMBNAIL",
                        "1280x720px",
                        "16:9",
                        "2MB"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("BaseUploadImage", {
                staticClass: "mt-2",
                attrs: { maxFilesize: 2, imageUrl: _vm.thumbnailImage },
                on: {
                  onUploadSuccess: (res) =>
                    _vm.onUploadThumbnailImage(res[0].link),
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }