var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.disabled
    ? _c(
        "div",
        { staticClass: "loading" },
        [
          _vm.customDisplay
            ? _vm._t("object-inside")
            : _c(
                "div",
                {
                  staticClass: "color--white relative dots-loading",
                  staticStyle: { "white-space": "nowrap" },
                },
                [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                  _c("span", { staticStyle: { "--i": "4" } }, [_vm._v(".")]),
                  _c("span", { staticStyle: { "--i": "7" } }, [_vm._v(".")]),
                  _c("span", { staticStyle: { "--i": "10" } }, [_vm._v(".")]),
                ]
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }